import React, { useState } from 'react';

import '../styles/watch.scss';

const Watch = () => {
  const [source, setSource] = useState('https://muvibg.com/');

  const sandboxOptions = ['allow-forms', 'allow-scripts', 'allow-same-origin', 'allow-top-navigation'];

  const handleClick = (source) => {
    switch (source) {
      case 'muvi':
        setSource('https://muvibg.com/');
        break;
      case 'filmche':
        setSource('http://filmche.eu/index.php');
        break;
      case 'zamundatv':
        setSource('https://zamundatv.com/');
        break;
      default:
        setSource('https://muvibg.com/');
        break;
    }
  };

  return (
    <div id="watch">
      <nav>
        <button onClick={() => handleClick('muvi')}>Muvi</button>
        <button onClick={() => handleClick('filmche')}>Filmche</button>
        <button onClick={() => handleClick('noviseriali')}>Novi Seriali</button>
      </nav>
      <iframe title="myFrame" sandbox={sandboxOptions.join(' ')} src={source} width="100%" allowFullScreen />
    </div>
  );
};

export default Watch;
